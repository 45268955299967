import React, { useState } from 'react';

function MyChapter() {
  // Déclare une nouvelle variable d'état, que l'on va appeler « Blood Ravens »
  const [chapterName] = useState("Blood Ravens");


  return (
    <div class="container">
      <h1>{chapterName}</h1>
      <div>
          <h2> Companies</h2>
          <div class="disRowCenter">
              <p>1er</p>
              <p>2e</p>
              <p>3e</p>
          </div>
          <div>
                <h3>1 Company</h3>
                <h4>Son of Guilliman</h4>
                <div class="disRow squadContainer">
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Marcus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Avitus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Redicus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Molus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Pollus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Marcus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Avitus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Redicus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Molus</p>
                    </div>
                    <div class="disCol singleModel">
                        <img class="thumbnail" alt="avatar"  />
                        <p>Pollus</p>
                    </div>
                </div>
          </div>
      </div>
    </div>
  );
}

export default MyChapter;