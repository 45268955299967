import React from 'react'
import { Link } from "react-router-dom";
class Header extends React.Component{
    render(){
        return (
            <div class="navbar">
                <div>
                    <Link to="/" class="linkHeader">home</Link>
                    <Link to="/mychapter" class="linkHeader">My Chapter</Link>
                    <Link to="/codex" class="linkHeader">Codex</Link>
                    <Link to="/signin" class="linkHeader">Sign-in</Link>
                </div>
                <div>
                    <input placeholder='Login' />
                    <input placeholder='password' />
                </div>
            </div>
        )
    }
}

export default Header