import './App.scss';
import { Routes, Route } from "react-router-dom";
import Header from './component/Header';
import MyChapter from './component/MyChapter';
import Home from './component/Home';
import Codex from './component/Codex';
import Signin from './component/Signin';
function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="MyChapter" element={<MyChapter />} />
        <Route path="Codex" element={<Codex />} />
        <Route path="Signin" element={<Signin />} />
      </Routes>
    </div>
  );
}

export default App;
