import React from 'react'
class Home extends React.Component{
    render(){
        return(
            <div>
                <h1>Bienvenue à theBloodRavens !</h1>
                <p>Le site est en construction, revenez plus tard !</p>
            </div>
        )
    }
}

export default Home;