import React from 'react'

function Codex(){
    return(
        <div>
            <h1>The Unofficial Codex</h1>
            <div>
                <h2>Faction</h2>
                <h3>Chapter</h3>
            </div>
            <div>
                <p>"Units" stats:</p>
                
            </div>
        </div>
    )
}

export default Codex;